#jsgTextEdit *, #jsgTextEdit *:before, #jsgTextEdit:after {
    position: static;
}

.App {
}

.App-header {
  background-color: #222;
  height: 50px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.react-grid-item {
    padding: 0.2em;
    border: thin solid #999999;
    border-radius: 0.5em;
}

span {
    font-size: 9pt;
}

#funcexampletable th {
    border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    padding: 3px;
}

#funcexampletable td {
    border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    padding: 3px;
}

#funcexampletable td:first-child {
    border-left: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
    padding: 3px;
    font-weight: bold;
}

#funcexampletable table {
     /*border-collapse: collapse;*/
    border-bottom: 1px solid lightgrey;
    border-right: 1px solid lightgrey;
    margin-bottom: 11px;
    margin-top: 6px;
    border-spacing: 0px;
}

div.sketch-picker {
    background-color: inherit !important;
}

@font-face {
    font-family: 'MetaPlusLF';
    src: local('MetaPlusLF');
    font-weight: normal;
    font-style: normal;
}

