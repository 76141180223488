body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}
span {
  font-size: unset;
}

